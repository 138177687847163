<template>
  <div style="display: flex; float:right !important">
    <v-icon color="white" class="pb-1 mr-1">
      mdi-account-circle-outline
    </v-icon>
    <div class="menu-profile" v-if="!smallScreen">
      <span class="menu-profile-label-user">
        {{ name || 'Anonimo' }}
      </span>
      <span class="menu-profile-label-seller">
        {{ companyName || 'Default seller' }}
      </span>
    </div>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" icon v-on="on">
          <v-icon color="white">
            mdi-menu-down
          </v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="changeRoute('/login')">
          <v-list-item-title>
            <v-btn dark icon>
              <v-icon color="primary">
                mdi-subdirectory-arrow-left
              </v-icon>
            </v-btn>
            <label>Sair</label>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import Vue from 'vue';
import { routerPush } from '@/service/kplaceRouter';
import utils from '@/utils/utils';

export default Vue.extend({
  computed: {
    name() {
      return JSON.parse(localStorage.getItem('current_user'))?.name;
    },
    companyName() {
      return JSON.parse(localStorage.getItem('current_user'))?.companyName;
    },
    selectedSeller() {
      return JSON.parse(localStorage.getItem('current_user'))?.sellerId;
    },
    smallScreen() {
      return utils.isSmallScreen(this);
    },
  },
  mounted() {
    if (!this.name || !this.selectedSeller) {
      this.changeRoute('/login');
    }
  },
  methods: {
    changeRoute(path) {
      routerPush(path);
    },
  },
});
</script>

<style>
.menu-profile {
  display: flex;
  flex-direction: column;
  margin-top: 0.9rem;
}
.menu-profile-label-user {
  text-align: center;
  font: normal normal bold 16px/19px Gotham Rounded, sans-serif;
  letter-spacing: 0;
  color: #ffffff;
  opacity: 1;
}

.menu-profile-label-seller {
  font: normal normal bold 16px/19px Gotham Rounded, sans-serif;
  letter-spacing: 0;
  opacity: 1;
  font-size: 0.9rem;
  color: #fefe00;
}
</style>
