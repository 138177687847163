import { state } from './main-state';
import getters from '@/store/main/getters';
import actions from '@/store/main/actions';
import mutations from '@/store/main/mutations';

const main = {
  state,
  getters,
  mutations,
  actions,
};

export default main;
