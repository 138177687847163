<template>

  <Content/>

</template>

<script>

import Content from '@/components/template/Content';
import { MutationTypesEnum } from '@/types/main/mutation-types';
import { routerPush } from '@/service/kplaceRouter';

export default {
  components: {
    Content,
  },
  mounted() {
    const item = localStorage.getItem('current_user');
    const currentUser = item ? JSON.parse(item) : null;
    if (currentUser === null || currentUser === 'null') {
      routerPush('/login');
    } else {
      this.$store.commit(MutationTypesEnum.SET_CURRENT_USER, currentUser);
      this.$store.commit(MutationTypesEnum.SET_IS_ADMIN, currentUser.isAdmin);
      this.$store.commit(MutationTypesEnum.SET_CURRENT_SELLER, currentUser.sellerId);
    }
  },
};

</script>

<style>
</style>
