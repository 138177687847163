/* eslint-disable no-shadow */
import { IMainState } from '../../store/main/main-state';
import { ICurrentUser } from '../current-user/ICurrentUser';

export enum MutationTypesEnum {
  SET_MENSAGEM_SUCESSO = 'SET_MENSAGEM_SUCESSO',
  SET_AUTHENTICATED = 'SET_AUTHENTICATED',
  SET_CURRENT_USER = 'SET_CURRENT_USER',
  SET_IS_ADMIN = 'SET_IS_ADMIN',
  SET_CURRENT_SELLER = 'SET_CURRENT_SELLER',
}

export type MutationsType<S = IMainState> = {
  [MutationTypesEnum.SET_MENSAGEM_SUCESSO](state: S, payload: string): void;
  [MutationTypesEnum.SET_AUTHENTICATED](state: S, payload: boolean): void;
  [MutationTypesEnum.SET_CURRENT_USER](state: S, payload: ICurrentUser | null): void;
  [MutationTypesEnum.SET_IS_ADMIN](state: S, payload: boolean): void;
  [MutationTypesEnum.SET_CURRENT_SELLER](state: S, payload: string): void;
};
