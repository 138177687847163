import VueRouter from 'vue-router';
import router from '@/router';

const { isNavigationFailure, NavigationFailureType } = VueRouter;

const routerPush = (newRoute: string): void => {
  router.push(newRoute)
    .catch((failure) => {
      if (isNavigationFailure(failure, NavigationFailureType.redirected)) {
        console.log('Deu ruim na navegação');
      }
    });
};

const routerBack = (): void => router.back();

const currentRoute = (): string => router.currentRoute.path;

export { routerPush, routerBack, currentRoute };
