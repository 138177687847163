var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',[_c('v-app-bar',{staticClass:"custom-header-background",attrs:{"app":""}},[(!_vm.smallScreen)?_c('v-img',{staticClass:"ml-1 mt-1",attrs:{"kplace_logomarca.svg":"","src":require("../../assets/images/header-image.png"),"max-width":"156px","max-heigth":"54px"},on:{"click":function($event){return _vm.changeRoute('/product')}}}):_c('v-img',{staticClass:"ml-1 mt-1",attrs:{"src":require("../../assets/images/favicon.png"),"max-width":"50px","width":"100","height":"50"},on:{"click":function($event){return _vm.changeRoute('/product')}}}),_c('v-sheet',{staticClass:"mx-auto",staticStyle:{"background-color":"rgba(0,0,0,0)"},attrs:{"max-width":"70%"}},[_c('v-slide-group',{attrs:{"multiple":"","show-arrows":"","active-class":"menu_text_selected"}},_vm._l((_vm.menu),function(itemMenu){return _c('v-slide-item',{key:itemMenu.id,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var toggle = ref.toggle;
return [((!itemMenu.sub || itemMenu.sub.length <= 0))?_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.isAble(itemMenu.adminPermission)),expression:"isAble(itemMenu.adminPermission)"}],class:_vm.getTitleClass(itemMenu),attrs:{"text":"","disabled":itemMenu.disabled},on:{"click":function($event){toggle();
              _vm.changeRoute(itemMenu.path);}}},[_vm._v(" "+_vm._s(itemMenu.name)+" ")]):_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.isAble(itemMenu.adminPermission)),expression:"isAble(itemMenu.adminPermission)"}],class:_vm.getTitleClass(itemMenu),attrs:{"text":"","disabled":itemMenu.disabled}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(itemMenu.name)+" "),_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-menu-down")])],1)]}}],null,true)},[_c('v-list',_vm._l((itemMenu.sub),function(item,index){return _c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isAble(item.adminPermission)),expression:"isAble(item.adminPermission)"}],key:index},[_c('v-list-item-title',{on:{"click":function($event){toggle();
                    _vm.changeRoute(item.path);}}},[_c('v-btn',{attrs:{"text":"","disabled":item.disabled}},[_vm._v(" "+_vm._s(item.name)+" ")])],1)],1)}),1)],1)]}}],null,true)})}),1)],1),_c('v-spacer'),_c('Profile')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }