import { GetterTree } from 'vuex';
import GetterType from '@/types/main/getter-type';
import { IMainState } from './main-state';

const getters: GetterTree<IMainState, IMainState> & GetterType = {
  authenticated: (mainState: IMainState) => {
    if (mainState?.authenticated) {
      return localStorage.getItem('token') != null && localStorage.getItem('token') !== 'null';
    }
    return false;
  },
  currentUser: (state: IMainState) => state?.currentUser,
  isAdmin: (state: IMainState) => state?.isAdmin,
  mensagemSucesso: (state: IMainState) => state.mensagemSucesso,
};

export default getters;
