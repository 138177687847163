import { ActionTree } from 'vuex';
import { IMainState } from '@/store/main/main-state';
import ActionsInterface from '@/types/main/actions-interface';
import { MutationTypesEnum } from '@/types/main/mutation-types';
import AugmentedActionContextType from '@/types/AugmentedActionContextType';
import MainActionTypesEnum from '@/types/main/action-types-enum';
import { ICurrentUser } from '@/types/current-user/ICurrentUser';

const actions: ActionTree<IMainState, IMainState> & ActionsInterface = {
  SET_AUTHENTICATED({ commit }: AugmentedActionContextType, payload: boolean): Promise<boolean> {
    commit(MutationTypesEnum.SET_AUTHENTICATED, payload);
    return Promise.resolve(false);
  },
  SET_MENSAGEM_SUCESSO({ commit }: AugmentedActionContextType, payload: string): Promise<string> {
    commit(MutationTypesEnum.SET_MENSAGEM_SUCESSO, payload);
    return Promise.resolve(payload);
  },
  SET_CURRENT_USER({ commit }: AugmentedActionContextType, payload: ICurrentUser | null): Promise<ICurrentUser | null> {
    commit(MutationTypesEnum.SET_CURRENT_USER, payload);
    commit(MutationTypesEnum.SET_IS_ADMIN, !!payload?.isAdmin);
    return Promise.resolve(payload);
  },
  [MainActionTypesEnum.LOGOUT_HANDLER]({ commit }: AugmentedActionContextType): Promise<void> {
    try {
      localStorage.removeItem('current_user');
      localStorage.removeItem('sellerId');
      commit(MutationTypesEnum.SET_CURRENT_USER, null);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default actions;
