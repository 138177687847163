import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/authentication/Login'),
    },
    {
        path: '/seller',
        name: 'seller',
        component: () => import('@/views/authentication/Seller'),
    },
    {
        path: '/sellers',
        name: 'sellers',
        component: () => import('@/views/seller/List'),
    },
    {
        path: '/category',
        name: 'Categoria',
        component: () => import('@/views/category/List'),
    },
    {
        path: '/category/forms',
        name: 'Dados Categoria',
        component: () => import('@/views/category/Form'),
    },
    {
        path: '/category/forms/edit/:id/:integrationPlatform',
        name: 'Dados Categoria',
        component: () => import('@/views/category/Form'),
    },
    {
        path: '/user',
        name: 'Usuário',
        component: () => import('@/views/user/List'),
    },
    {
        path: '/user/forms',
        name: 'Dados do Usuário',
        component: () => import('@/views/user/Form'),
    },
    {
        path: '/user/forms/edit/:id',
        name: 'Dados do Usuário',
        component: () => import('@/views/user/Form'),
    },
    {
        path: '/monitoring',
        name: 'Monitoramento',
        component: () => import('@/views/monitoring/List'),
    },
    {
        path: '/seller',
        name: 'Seller',
        component: () => import('@/views/seller/List'),
    },
    {
        path: '/seller/forms',
        name: 'Dados Seller',
        component: () => import('@/views/seller/Form'),
    },
    {
        path: '/seller/forms/edit/:id',
        name: 'Dados do Seller',
        component: () => import('@/views/seller/Form'),
    },
    {
        path: '/product',
        name: 'Produto',
        component: () => import('@/views/product/List'),
    },
    {
        path: '/product/forms',
        name: 'Dados Produto',
        component: () => import('@/views/product/Form'),
    },
    {
        path: '/product/forms/edit/:id',
        name: 'Dados Produto',
        component: () => import('@/views/product/Form'),
    },
    {
        path: '/product-type',
        name: 'Tipos de produtos',
        component: () => import('@/views/productTypes/List'),
    },
    {
        path: '/product-type/forms',
        name: 'Cadastrar Tipo de Produto',
        component: () => import('@/views/productTypes/Form'),
    },
    {
        path: '/product-type/forms/edit/:id',
        name: 'Dados Tipo de Produto',
        component: () => import('@/views/productTypes/Form'),
    },
    {
        path: '/order',
        name: 'Pedidos',
        component: () => import('@/views/order/List'),
    },
    {
        path: '/order/detail/:number',
        name: 'Detalhe do Pedido',
        component: () => import('@/views/order/Form'),
    },
    {
        path: '/',
        redirect: '/login',
    },
    {
        path: '/commission-rule',
        name: 'Regras de Comissão',
        component: () => import('@/views/commissionRules/List'),
    },
    {
        path: '/commission-rule/forms',
        name: 'Dados da Regra de Comissão',
        component: () => import('@/views/commissionRules/Form'),
    },
    {
        path: '/commission-rule/forms/edit/:id',
        name: 'Dados da Regra de Comissão',
        component: () => import('@/views/commissionRules/Form'),
    },
    {
        path: '/invoice-list',
        name: 'Lista de Faturas',
        component: () => import('@/views/invoice/List'),
    },
    {
        path: '/invoice/detail/:id',
        name: 'Detalhes da fatura',
        component: () => import('@/views/invoice/forms/Detail'),
    },
    {
        path: '/invoice-config',
        name: 'Configuração de Fatura',
        component: () => import('@/views/invoice/Form'),
    },
    {
        path: '/attendance',
        name: 'Atendimento',
        component: () => import('@/views/attendance/List'),
    },
    {
        path: '/attendance/forms/order/:orderId',
        name: 'Dados Atendimento',
        component: () => import('@/views/attendance/Form'),
    },
    {
        path: '/attendance/forms',
        name: 'Dados Atendimento',
        component: () => import('@/views/attendance/Form'),
    },
    {
        path: '/attendance/forms/edit/:id',
        name: 'Dados Atendimento',
        component: () => import('@/views/attendance/Form'),
    },
    {
        path: '/topic',
        name: 'Assuntos de Chamados de Atendimento',
        component: () => import('@/views/topic/List'),
    },
    {
        path: '/topic/forms',
        name: 'Assunto de Chamados de Atendimento',
        component: () => import('@/views/topic/Form'),
    },
    {
        path: '/topic/forms/edit/:id',
        name: 'Assunto de Chamados de Atendimento',
        component: () => import('@/views/topic/Form'),
    },
];

const router = new VueRouter({
    routes,
});

export default router;
