import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import VuePromiseBtn from 'vue-promise-btn';
import 'vue-promise-btn/dist/vue-promise-btn.css';
import Notify from '../components/notify';

Vue.use(Vuetify);

const vuetify = new Vuetify({});
const opt = {
  vuetify,
  options: {
    toast: {
      x: 'right',
      y: 'top',
    },
    dialog: {
      width: 400,
    },
  },
};
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
Vue.use(Notify, opt);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
Vue.use(VuePromiseBtn, {});

export default vuetify;
