import { MutationTree } from 'vuex';
import { MutationsType, MutationTypesEnum } from '@/types/main/mutation-types';
import { IMainState } from '@/store/main/main-state';
import { ICurrentUser } from '@/types/current-user/ICurrentUser';

const mutations: MutationTree<IMainState> & MutationsType = {
  [MutationTypesEnum.SET_MENSAGEM_SUCESSO](state, payload: string) {
    state.mensagemSucesso = payload;
  },
  [MutationTypesEnum.SET_CURRENT_USER](state: IMainState, payload: ICurrentUser | null) {
    state.currentUser = payload ? ({ ...payload } as ICurrentUser) : null;
  },
  [MutationTypesEnum.SET_IS_ADMIN](state: any, payload: boolean) {
    state.isAdmin = payload;
  },
  [MutationTypesEnum.SET_CURRENT_SELLER](state, payload: string) {
    state.currentSellerId = payload;
  },
  [MutationTypesEnum.SET_AUTHENTICATED](state, payload: boolean) {
    state.authenticated = payload;
  },
};

export default mutations;
