






import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import Home from '@/views/Home';

Vue.use(Vuetify);
Vue.prototype.groupIdAdmin = '0d024a15-bd8a-4c00-886a-0e43d81e2eca';
const opts = {
  theme: {
    themes: {
      light: {
        primary: '#07BEE7',
      },
    },
  },
};

export const vuetify = new Vuetify(opts);

export default {
  name: 'App',
  components: {
    Home,
  },
};
