import { ICurrentUser } from '@/types/current-user/ICurrentUser';

export interface IMainState {
  mensagemSucesso: string;
  currentUser: ICurrentUser | null;
  isAdmin: boolean;
  currentSellerId: string;
  authenticated: boolean;
  namespaced: boolean;
}

export const state = {
  mensagemSucesso: '',
  currentUser: null,
  currentSellerId: '',
  isAdmin: false,
  authenticated: false,
  namespaced: false,
};
