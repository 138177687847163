







import Vue from 'vue';
import Menu from '@/components/template/Menu';

export default Vue.extend({
  components: {
    Menu,
  },
  computed: {
    isLogin() {
      return this.$route.name !== 'login' && this.$route.name !== 'seller';
    },
  },
});
