export default {
  containsRequiredField(bools, bool) {
    for (let i = 0; i < bools.length; i += 1) {
      if (bools[i] === bool) {
        return true;
      }
    }
    return false;
  },
  revalidate(self) {
    const arkeys = Object.keys(self.steps);
    for (let i = 0; i < arkeys.length; i += 1) {
      const arProps = Object.values(self.steps[arkeys[i]].required);
      self.steps[arkeys[i]].status.valid = true;
      for (let j = 0; j < arProps.length; j += 1) {
        if (arProps[j]) {
          self.steps[arkeys[i]].status.valid = false;
        }
      }
    }
  },
  setStepStatus(self) {
    const arkeys = Object.keys(self.steps);
    for (let i = 0; i < arkeys.length; i += 1) {
      const arAttrs = Object.values(self.steps[arkeys[i]].required);
      if (self.editing && !self.saveWithInvalidForm) {
        self.steps[arkeys[i]].status = { valid: true, oppened: true };
      } else if (self.editing && self.saveWithInvalidForm && !this.containsRequiredField(arAttrs, true)) {
        self.steps[arkeys[i]].status = { valid: false, oppened: true };
      } else if (self.editing && self.saveWithInvalidForm && this.containsRequiredField(arAttrs, true)) {
        self.steps[arkeys[i]].status = { valid: true, oppened: true };
      } else if (this.containsRequiredField(arAttrs, true)) {
        self.steps[arkeys[i]].status = { valid: false, oppened: false };
      } else {
        self.steps[arkeys[i]].status = { valid: true, oppened: false };
      }
    }
  },
  setStepRequireds(self) {
    const arkeys = Object.keys(self.steps);
    for (let i = 0; i < arkeys.length; i += 1) {
      const arAttrKeys = Object.keys(self.steps[arkeys[i]].required);
      if (self.editing && !self.saveWithInvalidForm) {
        for (let j = 0; j < arAttrKeys.length; j += 1) {
          self.steps[arkeys[i]].required[arAttrKeys[j]] = false;
        }
      }
    }
  },
  listToIds(list) {
    const ids = [];
    if (list) {
      for (let i = 0; i < list.length; i += 1) {
        ids.push(list[i].id);
      }
    }
    return ids;
  },
  isSmallScreen(self) {
    return self.$vuetify.breakpoint.sm || self.$vuetify.breakpoint.xs;
  },
  isEmailValid(value) {
    // eslint-disable-next-line
    const regex = /[a-z0-9!#$%&'*+/=?^_{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;
    return value && regex.test(value);
  },
  setIconCheckAndErrorFromBoolean(item) {
    if (item === true) {
      return 'mdi-check';
    }
    return 'mdi-close';
  },
  setColorGreenAndRedFromBoolean(item) {
    if (item === true) {
      return '#02D13F';
    }
    return 'red';
  },
  getValueFromKey(arrayKeyValue, key) {
    const { value } = arrayKeyValue.find((keyValue) => keyValue.key === key) || {};
    if (!value) console.error('getValueFromKey: Não encontrado ', key);
    return value;
  },
};
