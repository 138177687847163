import Vue from 'vue';
import store from '../../store/index';

const directivesActions = {
  clearContent: (el, flag) => {
    const { isAdmin } = store?.getters?.currentUser;

    if (flag === isAdmin) {
      el.replaceChildren('');
    }
  },
  isAdminShow: (el) => {
    directivesActions.clearContent(el, false);
  },
  isAdminHide: (el) => {
    directivesActions.clearContent(el, true);
  },
};

Vue.directive('is-admin-show', {
  bind: (el) => directivesActions.isAdminShow(el),
  update: (el) => directivesActions.isAdminShow(el),
});

Vue.directive('is-admin-hide', {
  bind: (el) => directivesActions.isAdminHide(el),
  update: (el) => directivesActions.isAdminHide(el),
});
