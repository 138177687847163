<template>
  <nav>
    <v-app-bar app class="custom-header-background">
      <v-img
        v-if="!smallScreen"
        kplace_logomarca.svg
        src="../../assets/images/header-image.png"
        max-width="156px"
        max-heigth="54px"
        @click="changeRoute('/product')"
        class="ml-1 mt-1"
      />
      <v-img
        v-else
        src="../../assets/images/favicon.png"
        max-width="50px"
        width="100"
        height="50"
        @click="changeRoute('/product')"
        class="ml-1 mt-1"
      />

      <v-sheet style="background-color: rgba(0,0,0,0)" class="mx-auto" max-width="70%">
        <v-slide-group multiple show-arrows active-class="menu_text_selected">
          <v-slide-item v-for="itemMenu in menu" :key="itemMenu.id" v-slot="{ toggle }">
            <v-btn
              v-if="(!itemMenu.sub || itemMenu.sub.length <= 0)"
              v-show="isAble(itemMenu.adminPermission)"
              :class="getTitleClass(itemMenu)"
              text
              :disabled="itemMenu.disabled"
              @click="
                toggle();
                changeRoute(itemMenu.path);
              "
            >
              {{ itemMenu.name }}
            </v-btn>
            <v-menu v-else offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-show="isAble(itemMenu.adminPermission)" :class="getTitleClass(itemMenu)" text :disabled="itemMenu.disabled" v-bind="attrs" v-on="on">
                  {{ itemMenu.name }} <v-icon color="white">mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-for="(item, index) in itemMenu.sub" :key="index" v-show="isAble(item.adminPermission)">
                  <v-list-item-title
                    @click="
                      toggle();
                      changeRoute(item.path);
                    "
                  >
                    <v-btn text :disabled="item.disabled">
                      {{ item.name }}
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-slide-item>
        </v-slide-group>
      </v-sheet>
      <v-spacer></v-spacer>
      <Profile />
    </v-app-bar>
  </nav>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import Profile from './Profile';
import { IMenu, IMenus } from '@/types/IMenus';
import { routerPush, currentRoute } from '@/service/kplaceRouter';
import utils from '@/utils/utils';

// noinspection JSVoidFunctionReturnValueUsed
export default Vue.extend({
  components: {
    Profile,
  },
  props: {
    menu: {
      type: [],
      default: () => [
        {
          id: 1,
          name: 'Catálogo',
          default: true,
          sub: [
            {
              id: 101,
              name: 'Produtos',
              path: '/product',
              default: true,
            },
            {
              id: 102,
              name: 'Tipos de produtos',
              path: '/product-type',
              adminPermission: true,
            },
            {
              id: 103,
              name: 'Categorias',
              path: '/category',
            },
          ],
        },
        // {
        //   id: 2,
        //   name: 'Serviços',
        //   path: '/not-found',
        //   disabled: true,
        // },
        // {
        //   id: 5,
        //   name: 'Tipos Modais',
        //   path: '/not-found',
        //   disabled: true,
        // },
        {
          id: 3,
          name: 'Sellers',
          path: '/sellers',
          adminPermission: true,
        },
        {
          id: 4,
          name: 'Pedidos',
          path: '/order',
          disabled: false,
        },
        {
          id: 5,
          name: 'Financeiro',
          sub: [
            {
              id: 501,
              name: 'Regras de comissões',
              path: '/commission-rule',
            },
            {
              id: 503,
              name: 'Configurações de faturas',
              path: '/invoice-config',
            },
            {
              id: 502,
              name: 'Faturas de pagamentos',
              path: '/invoice-list',
            },
            //     {
            //       id: 503,
            //       name: 'Faturas de pagamentos',
            //       path: '/payment-invoices',
            //     },
          ],
        },
        // {
        //   id: 6,
        //   name: 'Catálogo',
        //   path: '/catalog',
        //   disabled: true,
        // },
        {
          id: 7,
          name: 'Usuários',
          path: '/user',
          adminPermission: true,
        },
        {
          id: 8,
          name: 'Gestão',
          adminPermission: true,
          sub: [
            {
              id: 801,
              name: 'Monitoramento',
              adminPermission: true,
              path: '/monitoring',
            },
            {
              id: 802,
              name: 'Assuntos',
              adminPermission: true,
              path: '/topic',
            },
          ],
        },
        {
          id: 9,
          name: 'Atendimento',
          path: '/attendance',
        },
      ],
    },
  },
  data: () => ({
    isAdministrador: true,
    drawer: '',
    user: 'Foo',
    path: '',
  }),
  computed: {
    ...mapGetters('authentication', ['menus']),
    smallScreen() {
      return utils.isSmallScreen(this);
    },
  },
  methods: {
    getTitleClass(itemMenu) {
      if (!currentRoute()) {
        return 'menu_text';
      }
      return currentRoute() === itemMenu.path ? 'menu_text_selected' : 'menu_text';
    },
    ...mapActions('authentication', ['nextStep']),
    changeRoute(path) {
      routerPush(path);
    },
    isAble(permission) {
      const { isAdmin } = this.$store.getters;
      if (!permission) return true;
      if (permission && isAdmin) return true;

      return false;
    },
  },
});
</script>

<style>
.larger-menu {
  height: 80px !important;
  width: 100%;
  z-index: 0 !important;
}

.custom-header-background {
  top: 0;
  left: 0;
  width: 1366px;
  height: 80px;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-origin: padding-box;
  background-image: linear-gradient(87deg, #0340a3 0, #0476c1 100%);
  background-color: transparent;
  background-clip: padding-box;
  box-shadow: 0 5px 6px #00000029;
  opacity: 1;
}

header {
  width: 100% !important;
}

.menu_text,
.menu_text_selected {
  color: white !important;
}

.profile_right {
  align: right;
}
</style>
